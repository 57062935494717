<template>
  <div v-if="service">
    <v-card flat class="auto elevation-4" height="100%">
      <v-card-text class="obj-title">
        <span class="title">{{ service.title }}</span>
        <v-btn x-small text color="accent" class="subaccent date-pub rounded-lg">{{service.name }}</v-btn>
      </v-card-text>

      <v-card-text class="h-vac-content">{{ service.domain }}</v-card-text>
      <v-card-text>
        <!-- <span class="secondary--text">Skills required:</span> <span class="error-text">No specified</span> -->
        <!-- <span class="ms-2 subprimary over-rounded">front dev </span>
            <span class="ms-2 subprimary over-rounded">full stack</span> -->
      </v-card-text>
      <v-card-actions>
        <v-btn small outlined color="primary" class="subprimary" @click="viewServiceDetails">View</v-btn>
        <v-btn small outlined color="secondary" class="subsecondary" @click="EditService">Edit</v-btn>
        <v-btn small outlined color="error" class="suberror" @click="deleteService">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'
var md5 = require("md5")

export default {
  props: {
    service: {
      type: Object,
      default: () => { }
    },
  },

  methods: {

    formattedDate(datetime) {
      const date = new Date(datetime);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return { 'date': `${day}/${month}/${year}`, 'time': `${hours}:${minutes}` };
    },
    goToreferenceLink(value) {
      this.$router.push(link)
    },
    EditService() {
      this.$router.push({ name: 'company.service.edit', params: { company: this.$store.getters.getCompany.name, value: this.service.id, title: md5(this.service.id + 'l') } })
    },
    viewServiceDetails() {
      this.$router.push({
        name: 'company.service.view', params: { company: this.$store.getters.getCompany.name, f_id: this.service.id, title: md5(this.service.id + 'l')}
      })
    },
    deleteService() { }
  },
  mount() {
    console.log(this.Service)
  },
  setup() {
    return {
      icons: {
        mdiOpenInNew
      },
    }
  },
}
</script>

<style lang="scss">
//@override
.v-card {
  border-radius: 1em !important;
}

.obj-title {
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: space-between;
  flex-direction: row;
}

.obj-title .title {
  font-size: 11pt !important;
  color: var(--v-secondary-base);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.obj-title .date-pub {
  font-size: 8pt !important;
  margin-left: 4px !important;
  padding: 0px 3px !important;
}

.h-vac-content {
  height: 4em !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-vacancie {
  width: 100%;
  display: block;
}
</style>